<template>
  <base-section id="404">
    <base-heading title="404" />

    <base-subheading
      class="primary--text"
      title="Oh!"
    />

    <base-subheading
      space="8"
      :title="$t('page-not-found')"
    />

    <base-img
      :src="require('@/assets/imgs/logoSmall.png')"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
<i18n>
{
	"en": {
		"page-not-found": "Page not found"
	},
	"it": {
		"page-not-found": "La pagina specificata non esiste"
	}
}
</i18n>
